<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="600px" height="600px">
      <!-- <v-overlay :value="loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="100" color="white">
          <span :style="colorProgress">กำลังเรียกคืน Inbox ในปีปัจจุบัน...</span>
        </v-progress-circular>
      </v-overlay> -->
      <v-card>
        <v-card-title>{{ $t("consent.limit_receipt_of_etax") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:600px" >
          <v-list>
            <!-- {{radio_check}} -->
            <v-layout style="justify-content: center;">
            <v-radio-group
              v-model="radio_check"
              row
            >
              <!-- รับทั้งหมด -->
              <v-radio
              :label='$t("consent.receive_all")'
                value="2"
                @click="check_radio_receive_etax_all()"
              ></v-radio>
              <!-- รับเฉพาะ -->
              <v-radio
              :label='$t("consent.receive_specificate")'
                value="1"
                @click="check_radio_except()"
              ></v-radio>
              <!-- ยกเว้น -->
              <v-radio
                :label='$t("consent.receive_all_with_except")'
                value="0"
                @click="check_radio_receive()"
              ></v-radio>
            </v-radio-group>
            </v-layout>
            <v-layout justify-center >
              <p 
              v-if="radio_check === '2'"
              style="color:#C51D1D"
              >
              <!-- รับ e-Tax จากบริษัททั้งหมด -->
              {{$t("consent.title_receive_all")}}
              </p>
              <p
              v-else-if="radio_check === '1'"
              style="color:#C51D1D"
              >
              <!-- รับ e-Tax เฉพาะบริษัทที่เลือกรับ -->
              {{$t("consent.title_receive_specificate")}}
              </p>
              <p
              v-else-if="radio_check === '0'"
              style="color:#C51D1D"
              >
              <!-- รับ e-Tax ทุกบริษัท / ยกเว้นบริษัทไม่ต้องการเลือกรับ -->
              {{$t("consent.title_receive_all_with_except")}}
              </p>
            </v-layout>
            <!-- เช็คข้อมูล Array -->
            <!-- {{datataxid}} -->
            <!-- เช็คข้อมูล taxid ที่ยกเว้น -->
            <!-- block_taxid : {{datataxid.block_taxid}}<br> -->
            <!-- เช็คข้อมูล taxid ที่ต้องการรับเฉพาะ -->
            <!-- accept_taxid : {{datataxid.accept_taxid}} -->
            <!-- ทดสอบ checkbox -->
            <!-- <template>
              <v-container fluid>
                <v-checkbox
                  v-model="checkbox1"
                  :label="`Checkbox 1: ${checkbox1.toString()}`"
                  @click="check()"
                ></v-checkbox>
                {{this.type_check}}
                <v-checkbox
                  v-model="checkbox2"
                  :label="`Checkbox 2: ${checkbox2.toString()}`"
                  @click="check2()"
                ></v-checkbox>
                {{this.type_check}}
              </v-container>
            </template> -->
            <!-- รับ ETAX จากบริษัททั้งหมด -->
              <!-- <v-checkbox
                class="mt-0"
                v-model="check_etax_get_all"
                color="primary"
                @click="check_etax_select = false, type_check = '0'"
              > -->
              <!-- เช็คกด เปิด-ปิด แบบสลับปุ่ม  -->
              <!-- <v-checkbox
                class="mt-0"
                v-model="check_etax_get_all"
                color="primary"
                @click="check_1()"
              >
                <template v-slot:label>
                    <v-card-text
                      class="pa-0 pt-0"
                      style="font-size:16px"
                    >
                      {{ $t("consent.receive_etax_all") }}
                    </v-card-text>
                </template>
              </v-checkbox> -->
                    <v-row v-if="check_etax_get_all === true">
                      <!-- โชว์ข้อมูล tax id -->
                      <v-col cols="12" class="mb-n12" style="margin-top:-10px">
                        <!-- ช่องเพิ่ม Tax ID block -->
                        <v-layout>
                        <v-flex xs12 sm12 md8 lg7 class="ml-5">
                        <v-text-field
                          v-model="block_taxid"
                          :label="$t('consent.input_block_taxid')"
                          dense
                          :oninput="regexTaxid"
                        ></v-text-field>
                          <!-- แจ้งเตือนการกรอกไม่ครบ -->
                          <!-- :rules="$t('default') === 'th' ? required_block_taxid : required_block_taxid_eng"
                          required
                          :error-messages="$t('default') === 'th' ? block_taxid_Error : block_taxid_Error_eng"
                          @input="$v.block_taxid.$touch()"
                          @blur="$v.block_taxid.$touch()" -->
                        </v-flex>
                        <v-btn 
                          :disabled="block_taxid.length !==13"
                          text
                          class="ml-1"
                          :color="color.theme" 
                          @click="fn_add_block_taxid()"
                        >
                          {{ $t("edithashtag.add") }}</v-btn
                        >
                        </v-layout>
                        <v-card-text
                          v-if="check_etax_get_all === true"
                          class="pa-0 pt-0 ml-5 mt-1"
                          style="font-size:16px;color:#C51D1D"
                        >
                          <b>
                          <!-- ยกเว้น -->
                          {{ $t("consent.except") }} :
                          <!-- แสดงจำนวนบริษัททั้งหมด -->
                          <!-- {{datataxid.block_taxid.length}} {{ $t("consent.company") }} -->
                          {{total_block_taxid}} {{ $t("consent.company") }}
                        <!-- <v-icon small color="#C51D1D">mdi-close-circle</v-icon> -->
                          </b>
                    </v-card-text>
                        <v-flex
                          style="max-height:285px;background-color:white;border:1px solid #EDEDFF;border-radius:5px;"
                          class="overflow-y-auto"
                        >
                        <v-list-item-group v-model="list_block">
                        <!-- แสดงข้อมูล 3 ค่า (ก่อนกดแสดงเพิ่มเติม)-->
                        <div v-if="show_more_data_list_tax === false">
                        <v-list-item
                          dense
                          v-for="(item, index) in data_list_tax.slice(0, 3)"
                          :key="index"
                          close
                          class="ma-1"
                          style="font-size:20px;"
                          @click:close="delete_block_taxid(index)"
                          >
                          <v-list-item-content class="pt-1">
                            <!-- <v-list-item-title class="mb-5" v-text="item.taxid"></v-list-item-title> -->
                            <!-- <v-list-item-title v-text="$t('default') === 'th' ? item.name_th : item.name_eng"></v-list-item-title> -->
                            <v-list-item-title
                            style="font-size:13px;line-height: 20px;"
                            >
                              {{item.taxid}} : 
                              <br>
                              {{$t('default') === 'th' ? item.name_th : item.name_eng}}
                              </v-list-item-title>
                          </v-list-item-content>
                          <!-- คลิ๊กแล้วปุ่มจะแสดง -->
                          <!-- v-if="model === index" -->
                          <v-btn
                          @mouseover="show_delete = true"
                          @mouseleave="show_delete = false"
                          icon
                          x-small
                          @click="delete_block_taxid(index)"
                          >
                            <v-icon
                            >mdi-close-circle</v-icon>
                          </v-btn>
                          <br>
                        </v-list-item>
                        <!-- </v-list-item-group> -->
                        </div>
                        <!-- แสดงข้อมูลทั้งหมด (หลังกดแสดงเพิ่มเติม)-->
                        <div v-else>
                        <v-list-item
                          dense
                          v-for="(item, index) in data_list_tax"
                          :key="index"
                          close
                          class="ma-1"
                          style="front-size:20px;"
                          @click:close="delete_block_taxid(index)"
                          >
                          <v-list-item-content class="pt-1">
                            <!-- <v-list-item-title class="mb-5" v-text="item.taxid"></v-list-item-title> -->
                            <!-- <v-list-item-title v-text="$t('default') === 'th' ? item.name_th : item.name_eng"></v-list-item-title> -->
                            <v-list-item-title
                            style="font-size:13px;line-height: 20px;"
                            >
                              {{item.taxid}} : 
                              <br>
                              {{$t('default') === 'th' ? item.name_th : item.name_eng}}
                              </v-list-item-title>
                          </v-list-item-content>
                          <!-- {{item.taxid}} : {{$t('default') === 'th' ? item.name_th : item.name_eng}} -->
                          <v-btn
                          @mouseover="show_delete = true"
                          @mouseleave="show_delete = false"
                          icon
                          x-small
                          @click="delete_block_taxid(index)"
                          >
                            <v-icon
                            >mdi-close-circle</v-icon>
                          </v-btn>
                          <br>
                        </v-list-item>
                        </div>
                        </v-list-item-group>
                        <!-- block จำนวนบริษัทน้อยกว่า 3 บริษัท จะไม่แสดงเพิ่มเติมกับไม่แสดงน้อยลง -->
                        <v-layout v-if="total_block_taxid > 3">
                        <!-- ...แสดงเพิ่มเติม block-->
                        <p
                        v-if="show_more_data_list_tax === false"
                        class="ml-5"
                        style="front-size:10px;cursor:pointer;color:#6495eddb;"
                        color="primary"
                        @click="block_show_more()"
                        >
                        {{ $t("consent.show_more") }}
                        </p>
                        <!-- ...แสดงน้อยลง block -->
                        <p
                        v-else
                        class="ml-5"
                        style="front-size:10px;cursor:pointer;color:#6495eddb;"
                        color="primary"
                        @click="block_show_less()"
                        >
                        {{ $t("consent.show_less") }}
                        </p>
                        </v-layout>
                        <!-- ช่องเพิ่ม Tax ID block -->
                        <!-- <v-layout>
                        <v-flex xs12 sm12 md8 lg5 class="ml-5">
                        <v-text-field
                          v-model="block_taxid"
                          :label="$t('TaxID')"
                          dense
                          :rules="$t('default') === 'th' ? required_block_taxid : required_block_taxid_eng"
                          required
                          :error-messages="$t('default') === 'th' ? block_taxid_Error : block_taxid_Error_eng"
                          @input="$v.block_taxid.$touch()"
                          :oninput="regexTaxid"
                          @blur="$v.block_taxid.$touch()"
                        ></v-text-field>
                        </v-flex>
                        <v-btn 
                          :disabled="block_taxid.length !==13"
                          text
                          class="ml-1"
                          :color="color.theme" 
                          @click="fn_add_block_taxid()"
                        >
                          {{ $t("edithashtag.add") }}</v-btn
                        >
                        </v-layout> -->
                        </v-flex>
                      </v-col>
                      <v-col cols="10" sm="10" style="margin-top:40px">
                        <!-- <v-layout>
                        <v-flex xs12 sm12 md8 lg6 class="ml-5">
                        <v-text-field
                          v-model="block_taxid"
                          :label="$t('TaxID')"
                          outlined
                          dense
                          maxlength="13"
                          :rules="$t('default') === 'th' ? required_block_taxid : required_block_taxid_eng"
                          required
                          :error-messages="$t('default') === 'th' ? block_taxid_Error : block_taxid_Error_eng"
                          @input="$v.block_taxid.$touch()"
                          @blur="$v.block_taxid.$touch()"
                        ></v-text-field>
                        </v-flex>
                        <v-btn 
                          class="ml-7"
                          dark 
                          :color="color.theme" 
                          @click="fn_add_block_taxid()"
                        >
                          {{ $t("edithashtag.add") }}</v-btn
                        >
                        </v-layout> -->
                      </v-col>
                      <!-- <v-col cols="2" sm="2">
                        <v-btn 
                          dark 
                          :color="color.theme" 
                          @click="fn_add_block_taxid()"
                        >
                          {{ $t("edithashtag.add") }}</v-btn
                        >
                      </v-col> -->
                    </v-row>
              <!-- รับ ETAX เฉพาะ -->
              <!-- <v-checkbox
                class="mt-0"
                v-model="check_etax_select"
                color="primary"
                style="margin-left:0px;"
                @click="check_etax_get_all = false, type_check = '1'"
              > -->
              <!-- เช็คกด เปิด-ปิด แบบสลับปุ่ม  -->
              <!-- <v-checkbox
                class="mt-0"
                v-model="check_etax_select"
                color="primary"
                style="margin-left:0px;"
                @click="check_2()"
              >
                  <template v-slot:label>
                    <v-card-text
                      class="pa-0 pt-0"
                      style="font-size:16px"
                    >
                      {{ $t("consent.receive_etax") }}
                    </v-card-text>
                  </template>
              </v-checkbox> -->
              <v-row v-if="check_etax_select === true">
                <!-- โชว์ข้อมูล tax id -->
                <v-col cols="12" style="margin-top:-10px">
                  <!-- ช่องเพิ่ม Tax ID accept -->
                  <!-- :label="$t('consent.pleasefill_accept_taxid')" -->
                  <v-layout>
                    <v-flex xs12 sm12 md8 lg7 class="ml-5">
                    <v-text-field
                      v-model="accept_taxid"
                      :label="$t('consent.input_accept_taxid')"
                      dense
                      :oninput="regexTaxid"
                    ></v-text-field>
                    <!-- แจ้งเตือนการกรอกไม่ครบ -->
                      <!-- :rules="$t('default') === 'th' ? required_accept_taxid : required_accept_taxid_eng"
                      required
                      :error-messages="$t('default') === 'th' ? accept_taxid_Error : accept_taxid_Error_eng"
                      @input="$v.accept_taxid.$touch()"
                      @blur="$v.accept_taxid.$touch()" -->
                    </v-flex>
                      <v-btn
                      :disabled="accept_taxid.length !==13"
                      text
                      class="ml-1" 
                      :color="color.theme" 
                      @click="fn_add_accept_taxid()"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                  </v-layout>
                  <v-card-text
                      class="pa-0 pt-0 ml-5 mt-1"
                      style="font-size:16px;"
                    >
                    <b>
                   {{ $t("consent.total") }} :
                   <!-- แสดงจำนวนบริษัททั้งหมด -->
                   <!-- {{datataxid.accept_taxid.length}} {{ $t("consent.company") }} -->
                   {{total_accept_taxid}} {{ $t("consent.company") }}
                    </b>
                  </v-card-text>
                  <v-flex
                    style="max-height:300px;background-color:white;border:1px solid #EDEDFF;border-radius:5px;"
                    class="overflow-y-auto"
                  >
                  <v-list-item-group v-model="list_accept">
                  <!-- แสดงข้อมูล 3 ค่า (ก่อนกดแสดงเพิ่มเติม)-->
                  <div v-if="show_more_data_list_accept_tax === false">
                  <v-list-item
                    dense
                    v-for="(item, index) in data_list_accept_tax.slice(0, 3)"
                    :key="index"
                    close
                    class="ma-1"
                    style="front-size:20px"
                    @click:close="delete_accept_taxid(index)"
                    >
                    <v-list-item-content class="pt-1">
                      <v-list-item-title
                      style="font-size:13px;line-height: 20px;"
                      >
                            {{item.taxid}} : 
                            <br>
                            {{$t('default') === 'th' ? item.name_th : item.name_eng}} 
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn
                    @mouseover="show_delete = true"
                    @mouseleave="show_delete = false"
                    icon
                    x-small
                    @click="delete_accept_taxid(index)"
                    >
                      <v-icon
                      >mdi-close-circle</v-icon>
                    </v-btn>
                    <br>
                  </v-list-item>
                  </div>
                  <!-- แสดงข้อมูลทั้งหมด (หลังกดแสดงเพิ่มเติม)-->
                  <div v-else>
                  <v-list-item
                    dense
                    v-for="(item, index) in data_list_accept_tax"
                    :key="index"
                    close
                    class="ma-1"
                    style="front-size:20px"
                    @click:close="delete_accept_taxid(index)"
                    >
                    <v-list-item-content class="pt-1">
                      <v-list-item-title
                      style="font-size:13px;line-height: 20px;"
                      >
                        {{item.taxid}} : 
                        <br>
                        {{$t('default') === 'th' ? item.name_th : item.name_eng}} 
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-btn
                    @mouseover="show_delete = true"
                    @mouseleave="show_delete = false"
                    icon
                    x-small
                    @click="delete_accept_taxid(index)"
                    >
                      <v-icon
                      >mdi-close-circle</v-icon>
                    </v-btn>
                    <br>
                  </v-list-item>
                  </div>
                  </v-list-item-group>
                  <!-- accept จำนวนบริษัทน้อยกว่า 3 บริษัท จะไม่แสดงเพิ่มเติมกับไม่แสดงน้อยลง -->
                  <v-layout v-if="total_accept_taxid > 3">
                    <!-- ...แสดงเพิ่มเติม accept -->
                    <p
                      v-if="show_more_data_list_accept_tax === false"
                      class="ml-5"
                      style="front-size:10px;cursor:pointer;color:#6495eddb;"
                      color="primary"
                      @click="accept_show_more()"
                    >
                      {{ $t("consent.show_more") }}
                    </p>
                    <!-- ...แสดงน้อยลง accept -->
                    <p
                      v-else
                      class="ml-5"
                      style="front-size:10px;cursor:pointer;color:#6495eddb;"
                      color="primary"
                      @click="accept_show_less()"
                    >
                      {{ $t("consent.show_less") }}
                    </p>
                  </v-layout>
                  <!-- ช่องเพิ่ม Tax ID accept -->
                  <!-- <v-layout>
                    <v-flex xs12 sm12 md8 lg5 class="ml-5">
                    <v-text-field
                      v-model="accept_taxid"
                      :label="$t('TaxID')"
                      dense
                      :rules="$t('default') === 'th' ? required_accept_taxid : required_accept_taxid_eng"
                      required
                      :error-messages="$t('default') === 'th' ? accept_taxid_Error : accept_taxid_Error_eng"
                      @input="$v.accept_taxid.$touch()"
                      @blur="$v.accept_taxid.$touch()"
                      :oninput="regexTaxid"
                    ></v-text-field>
                    </v-flex>
                  <v-btn
                  :disabled="accept_taxid.length !==13"
                  text
                  class="ml-1" 
                  :color="color.theme" 
                  @click="fn_add_accept_taxid()"
                  >
                    {{ $t("edithashtag.add") }}</v-btn
                  >
                  </v-layout> -->
                  </v-flex>
                </v-col>
                <!-- <v-col cols="10" sm="10">
                  <v-layout>
                  <v-flex xs12 sm12 md8 lg8 >
                  <v-text-field
                    v-model="accept_taxid"
                    :label="$t('TaxID')"
                    outlined
                    dense
                    maxlength="13"
                    :rules="$t('default') === 'th' ? required_accept_taxid : required_accept_taxid_eng"
                    required
                    :error-messages="$t('default') === 'th' ? accept_taxid_Error : accept_taxid_Error_eng"
                    @input="$v.accept_taxid.$touch()"
                    @blur="$v.accept_taxid.$touch()"
                  ></v-text-field>
                  </v-flex>
                  <v-btn
                  class="ml-7" 
                  dark 
                  :color="color.theme" 
                  @click="fn_add_accept_taxid()"
                  >
                    {{ $t("edithashtag.add") }}</v-btn
                  >
                  </v-layout>
                </v-col> -->
                <!-- <v-col cols="2" sm="2">
                  <v-btn 
                  dark 
                  :color="color.theme" 
                  @click="fn_add_accept_taxid()"
                  >
                    {{ $t("edithashtag.add") }}</v-btn
                  >
                </v-col> -->
              </v-row>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มยกเลิก -->
          <v-btn 
            color="#C51D1D" 
            outlined 
            @click="$emit('close'), checkfuntion = false , clear()"
          >
            {{ $t("consent.cancel") }}</v-btn>
            <!-- ปุ่มยืนยัน -->
          <v-btn
            :color="color.theme"
            class="white--text"
            @click="check_btn(),clear()"
          >
            {{ $t("consent.save") }}
          </v-btn>
        </v-card-actions>

        <dialogpolicy
          :show="opendialogpolicy"
          :datapolicy="selectedpolicy"
          @close="opendialogpolicy = false"          
        ></dialogpolicy>        
        <dialogconsentdetail
          :show="opendialogconsentdetail"
          :statusconsent="status_consent"
          @close="opendialogconsentdetail = false"
        ></dialogconsentdetail>

      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
const dialogpolicy = () => import("./dialog-policy.vue");
const dialogconsentdetail = () => import("./dialog-consentdetail.vue");
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ['show', "dataconsent","datataxid"],
  mixins: [validationMixin],
  validations: {
    block_taxid: { required },
    accept_taxid: { required }
  },
  components: {
    dialogpolicy,
    dialogconsentdetail,
  },
  data() {
    return {
      list_block:1,
      list_accept:1,
      radio_check:"",
      show_more_data_list_tax:false,
      show_more_data_list_accept_tax:false,
      regexTaxid: "this.value = this.value.replace(/[^0-9]|/g, '').substr(0,13)",
      type_check:"",
      checkbox1:false,
      checkbox2:false,
      checkfuntion:false,
      list_block_taxid:[],
      data_tax:[],
      type_tax:"",
      show_delete:false,
      dataIndex:0,
      accept_taxid:"",
      block_taxid:"",
      status_block:"",
      checkhashtag: "",
      hashtag: "",
      data_list_tax: [],
      data_list_accept_tax: [],
      total_block_taxid:0,
      total_accept_taxid:0,
      nubcheck:0,
      loaddataprogress:false,
      opendialogconsentdetail: false,
      status_consent: "",
      features: [],
      opendialogpolicy: false,
      selectedpolicy: {},
      check_etax_get_all: false,
      check_etax_except: false,
      check_etax_select:false,
      inputs: [
        {
          taxid:"",
        },
      ],
      inputs_select: [
        {
          taxid:"",
        },
      ],
      listtaxid: [
        {
          taxid: "0105517005330",
          name_th: "บริษัท สรรพสินค้าเซ็นทรัล จำกัด",
        },
        {
          taxid: "0105511004676",
          name_th: "บริษัท ห้างเซ็นทรัล ดีพาทเมนท์สโตร์ จำกัด",
        },
        {
          taxid: "0105511004650",
          name_th: "บริษัท เตียง จิราธิวัฒน์ จำกัด",
        },
        {
          taxid: "0105558163762",
          name_th: "บริษัท เซ็นทรัล เพย์เม้นท์ จำกัด",
        }
      ],
      required_block_taxid: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => (value && value.length == 13 && this.fn_check_taxid(value)) || "โปรดระบุตัวเลขประจำตัวผู้เสียภาษี 13 หลัก",
      ],
      required_block_taxid_eng: [
        (value) => !!value || "Please fill Tax ID.",
        (value) => (value && value.length == 13 && this.fn_check_taxid(value)) || "Please fill Tax ID 13 digits.",
      ],
      required_accept_taxid: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => (value && value.length == 13 && this.fn_check_taxid(value)) || "โปรดระบุตัวเลขประจำตัวผู้เสียภาษี 13 หลัก",
      ],
      required_accept_taxid_eng: [
        (value) => !!value || "Please fill Tax ID.",
        (value) => (value && value.length == 13 && this.fn_check_taxid(value)) || "Please fill Tax ID 13 digits.",
      ],
    }
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    block_taxid_Error() {
      const errors = [];
      if (!this.$v.block_taxid.$dirty) return errors;
      !this.$v.block_taxid.required && errors.push("โปรดระบุเลขประจำตัวผู้เสียภาษี");
      return errors;
    },
    block_taxid_Error_eng() {
      const errors = [];
      if (!this.$v.block_taxid.$dirty) return errors;
      !this.$v.block_taxid.required && errors.push("Please fill Tax ID.");
      return errors;
    },
    accept_taxid_Error() {
      const errors = [];
      if (!this.$v.accept_taxid.$dirty) return errors;
      !this.$v.accept_taxid.required && errors.push("โปรดระบุเลขประจำตัวผู้เสียภาษี");
      return errors;
    },
    accept_taxid_Error_eng() {
      const errors = [];
      if (!this.$v.accept_taxid.$dirty) return errors;
      !this.$v.accept_taxid.required && errors.push("Please fill Tax ID.");
      return errors;
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },  
    shower: {
      get() {
        if (this.show === true) {
          console.log("show",this.show);
          console.log("12",this.datataxid);
          if(this.checkfuntion === false){

          if(this.datataxid.status_accept === '0'){
          console.log("this.datataxid.status_accept",this.datataxid.status_accept);
          // this.status_block = this.datataxid.status_accept
          console.log("status_block",this.status_block);
          // this.checkbox1 = true
          // this.checkbox2 = false
          this.radio_check = this.datataxid.status_accept
          this.check_etax_get_all = true
          this.check_etax_select = false
          this.type_check = this.datataxid.status_accept
          if(this.datataxid.block_taxid !== []){
            this.data_list_tax = [];
            this.fn_get_nametax_block()
          }
          if(this.datataxid.accept_taxid !== []){
            this.data_list_accept_tax = [];
            this.fn_get_nametax_accept()
          }
          
          }else if(this.datataxid.status_accept === '1'){
          // this.checkbox2 = true
          // this.checkbox1 = false
          this.radio_check = this.datataxid.status_accept
          this.check_etax_select = true
          this.check_etax_get_all = false
          this.type_check = this.datataxid.status_accept
          if(this.datataxid.accept_taxid !== []){
            this.data_list_accept_tax = [];
            this.fn_get_nametax_accept()
          }
          if(this.datataxid.block_taxid !== []){
            this.data_list_tax = [];
            this.fn_get_nametax_block()
          }
          }else if(this.datataxid.status_accept === '2'){
            this.radio_check = this.datataxid.status_accept
            this.check_etax_select = false
            this.check_etax_get_all = false
            // this.fn_get_nametax_block()
            // this.fn_get_nametax_accept()
          }
          }
        }
        return this.show
      },
    },
  },
  methods: {
    block_show_more(){
      this.show_more_data_list_tax = true;
      // const citrus = this.datataxid.block_taxid.slice(5) ;
      // document.getElementById("demo").innerHTML = citrus;
    },
    block_show_less(){
      this.show_more_data_list_tax = false;
    },
    accept_show_more(){
      this.show_more_data_list_accept_tax = true;
    },
    accept_show_less(){
      this.show_more_data_list_accept_tax = false;
    },
    clear(){
      this.accept_taxid = "",
      this.block_taxid = ""
    },
    // เช็คค่าว่าเป็นตัวเลข
    fn_check_taxid(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    // isLetterOrNumber(block_taxid) {
    //   this.block_taxid = this.block_taxid.replace(/[^0-9]/g, "");
    // },
    // เพิ่มเข้า taxid เข้า Block
    fn_add_block_taxid(){
        this.checkfuntion = true;
       if(this.block_taxid.length === 13){
        console.log("block_taxid",this.block_taxid);
        // เช็ค Array
        console.log(this.datataxid.block_taxid.constructor.name)
        // เช็คค่าซ้ำใน Array
        if(this.datataxid.block_taxid.indexOf(this.block_taxid) !== -1){
          console.log("กรณี ซ้ำ");
            Toast.fire({
            // icon: "warning",
            // title: this.$t("consent.taxid_duplicate")
            icon: "success",
            title: this.$t("consent.added_taxid")
            })
            // เคลียร์ช่องกรอก Tax ID
            this.block_taxid = ""
        }else{
          // เพิ่มค่าเข้า Array
          console.log("กรณี ไม่ซ้ำ");
          this.fn_check_push(this.block_taxid);
        }
        }else{
          Toast.fire({
          icon: "warning",
          title: "กรุณากรอก TAX ID ที่ต้องการยกเว้น"
          })
        }
    },
    // เพิ่มเข้า taxid เข้า Accept
    fn_add_accept_taxid(){
      this.checkfuntion = true;
       if(this.accept_taxid.length === 13){
        console.log("accept_taxid",this.accept_taxid);
         // เช็ค Array
        console.log(this.datataxid.accept_taxid.constructor.name)
        // เช็คค่าซ้ำใน Array
        if(this.datataxid.accept_taxid.indexOf(this.accept_taxid) !== -1){
          console.log("กรณี ซ้ำ");
            Toast.fire({
            // icon: "warning",
            // title: this.$t("consent.taxid_duplicate")
            icon: "success",
            title: this.$t("consent.added_taxid")
          })
          // เคลียร์ช่องกรอก Tax ID
          this.accept_taxid = ""
        }else{
          // เพิ่มค่าเข้า Array
          console.log("กรณี ไม่ซ้ำ");
          this.fn_check_push_accept(this.accept_taxid);
        }

        }else{
          Toast.fire({
          icon: "warning",
          title: "กรุณากรอก TAX ID ที่ต้องการรับเฉพาะ"
          })
        }
    },
    // push ค่า block_taxid และ get ข้อมูลชื่อตาม taxid
    async fn_check_push(taxid){
      let payload = {
        taxid:taxid
      };
      console.log('payload', payload);
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_business_name_by_taxid", payload, {
            headers: { Authorization: auth.code },
          })
          .then(response => {
            if (response.data.status === "OK") {
              console.log("เพิ่มค่า taxid เข้า []");

                this.datataxid.block_taxid.push(this.block_taxid);
                console.log("***",this.datataxid.block_taxid);
                this.data_list_tax = [];
                this.fn_get_nametax_block();
                Toast.fire({
                icon: "success",
                title: this.$t("consent.added_taxid")
              })
              // เคลียร์ช่องกรอก Tax ID
              this.block_taxid = ""
            }else{
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ': ' + response.data.errorMessage_th,
              });
            }
            }).catch(error => {
            console.log("error", error);  
            Toast.fire({
              icon: "error",
              title: error
            });
          })
    },
    // push ค่า accept_taxid และ get ข้อมูลชื่อตาม taxid
    async fn_check_push_accept(taxid){
      let payload = {
        taxid:taxid
      };
      console.log('payload', payload);
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_business_name_by_taxid", payload, {
            headers: { Authorization: auth.code },
          })
          .then(response => {
            if (response.data.status === "OK") {
              console.log("เพิ่มค่า accept_taxid เข้า []");

                this.datataxid.accept_taxid.push(this.accept_taxid);
                console.log("***",this.datataxid.accept_taxid);
                this.data_list_accept_tax = [];
                this.fn_get_nametax_accept();
                Toast.fire({
              icon: "success",
              title: this.$t("consent.added_taxid")
              })
              // เคลียร์ช่องกรอก Tax ID
              this.accept_taxid = ""
            }else{
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ': ' + response.data.errorMessage_th,
              });
            }
            }).catch(error => {
            console.log("error", error);  
            Toast.fire({
              icon: "error",
              title: error
            });
          })
    },
    // วนค่า array ใน block_taxid และ get ชื่อ taxid แล้ว push ใส่ค่า data_list_tax เพื่อนำไปแสดง บน รับ ETAX ทั้งหมด (ยกเว้น)
    async fn_get_nametax_block(){
      this.data_list_tax = [];
      console.log("ยิง",this.datataxid.block_taxid);
      for(let i = 0; i < this.datataxid.block_taxid.length; i++){
        console.log("datataxid",this.datataxid.block_taxid[i]);
        // this.test(this.datataxid.block_taxid[i])

      let payload = {
        taxid:this.datataxid.block_taxid[i]
      };
      console.log('payload', payload);
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_business_name_by_taxid", payload, {
            headers: { Authorization: auth.code },
          })
          .then(response => {
            if (response.data.status === "OK") {
              console.log("ok block_taxid",this.data_tax);
            this.data_tax = response.data.data;
            // console.log(" this.data_tax",this.data_tax);
            // console.log("data_tax_th",this.data_tax.name_th);
            // console.log("data_tax_eng",this.data_tax.name_eng);
            this.data_list_tax.push({
              taxid:this.datataxid.block_taxid[i],
              name_th:this.data_tax.name_th,
              name_eng:this.data_tax.name_eng
            // this.data_list_tax.push(this.data_tax);
            })
            // จำนวนบริษัททั้งหมด block
            this.total_block_taxid = this.datataxid.block_taxid.length
            }else{
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ': ' + response.data.errorMessage,
              });
            }

            if(i ===  this.datataxid.block_taxid.length - 1 ){
              console.log("ครบ");
            }
            }).catch(error => {
            console.log("error", error);  
            Toast.fire({
              icon: "error",
              title: error
            });
          })
       }
    },
    // วนค่า array ใน accept_taxid และ get ชื่อ taxid แล้ว push ใส่ค่า data_list_accept_tax เพื่อนำไปแสดง บน รับ ETAX เฉพาะ
    async fn_get_nametax_accept(){
      this.data_list_accept_tax = [];
      console.log("ยิง accept_taxid",this.datataxid.accept_taxid);
      for(let i = 0; i < this.datataxid.accept_taxid.length; i++){
        console.log("datataxid",this.datataxid.accept_taxid[i]);

      let payload = {
        taxid:this.datataxid.accept_taxid[i]
      };
      console.log('payload', payload);
        let auth = await gbfGenerate.generateToken();
       await this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/get_business_name_by_taxid", payload, {
            headers: { Authorization: auth.code },
          })
          .then(response => {
            if (response.data.status === "OK") {
              console.log("ok accept_taxid");
            this.data_tax = response.data.data;

            this.data_list_accept_tax.push({
              taxid:this.datataxid.accept_taxid[i],
              name_th:this.data_tax.name_th,
              name_eng:this.data_tax.name_eng
            })
            // จำนวนบริษัททั้งหมด accept
            this.total_accept_taxid = this.datataxid.accept_taxid.length
            }else{
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ': ' + response.data.errorMessage,
              });
            }

            if(i ===  this.datataxid.accept_taxid.length - 1 ){
              console.log("ครบ");
            }
            }).catch(error => {
            console.log("error", error);  
            Toast.fire({
              icon: "error",
              title: error
            });
          })
       }
    },
    // ยืนยันการเซ็ตและตั้งค่า TAX ID 
    async fn_set_accept_inbox(){
      console.log("fn_set_accept_inbox");
      let payload = {
        account_id: this.dataAccountId,
        business_id:this.dataAccountActive.business_info.business_id,
        accept_taxid: this.datataxid.accept_taxid,
        block_taxid: this.datataxid.block_taxid,
        // status_accept:this.type_check
        status_accept:this.radio_check
      };
      console.log('payload', payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/v1/set_accept_inbox", payload, {
            headers: { Authorization: auth.code },
          })
          .then(response => {
            if (response.data.status === "OK") {
              console.log("update block_tax",this.datataxid.block_taxid);
              console.log("update data_list_tax",this.data_list_tax);
              console.log("update accept_tax",this.datataxid.accept_taxid);
              console.log("update data_list_accept_tax",this.datataxid.accept_taxid);
              Toast.fire({
              icon: "success",
              title: this.$t("consent.set_successfully")
              })
            this.$emit('close')
            // this.data_list_tax = []
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ': ' + response.data.errorMessage,
              });
              this.$emit('close');
            }
          }).catch(error => {
            console.log("error", error);  
            Toast.fire({
              icon: "error",
              title: error
            });
          })
    },
    // ลบ taxid ของ block_taxid
    delete_block_taxid(index) {
      console.log("ก่อนลบ", index);
      // ลบค่าที่แสดงใน v-list
      this.data_list_tax.splice(index, 1);
      console.log("this.data_list_tax", this.data_list_tax);
      // ลบค่าใน block_taxid
      this.datataxid.block_taxid.splice(index, 1);
      console.log("this.datataxid.block_taxid", this.datataxid.block_taxid);
      this.datataxid.status_accept = '0'
      // จำนวนบริษัททั้งหมด block
      this.total_block_taxid = this.datataxid.block_taxid.length;
      // แจ้งเตือนตอน ลบ tax
      // Toast.fire({
      //   icon: "success",
      //   title:"ลบ TAX ID สำเร็จ"
      //   });
    },
    // ลบ taxid ของ accept_taxid
    delete_accept_taxid(index){
      console.log("ก่อนลบ", index);
      // ลบค่าที่แสดงใน v-list
      this.data_list_accept_tax.splice(index, 1);
      console.log("this.data_list_accept_tax", this.data_list_accept_tax);
      // ลบค่าใน accept_taxid
      this.datataxid.accept_taxid.splice(index, 1);
      console.log("this.datataxid.accept_taxid", this.datataxid.accept_taxid);
      this.datataxid.status_accept = '1'
      // จำนวนบริษัททั้งหมด accept
      this.total_accept_taxid = this.datataxid.accept_taxid.length;
      // แจ้งเตือนตอน ลบ tax
      // Toast.fire({
      //   icon: "success",
      //   title:"ลบ TAX ID สำเร็จ"
      //   });
    },
     check_btn(){
      console.log("check_btn");
      this.fn_set_accept_inbox()
    },
    check_1(){
      if(this.check_etax_get_all == false){
        this.check_etax_get_all = false
        this.check_etax_select = true
        this.type_check = '1'
      }else{
        this.check_etax_get_all = true
        this.check_etax_select = false
        this.type_check = '0'
      }
    },
    check_2(){
      if (this.check_etax_select == false){
        this.check_etax_select = false
        this.check_etax_get_all = true
        this.type_check = '0'
      }else{
        this.check_etax_select = true
        this.check_etax_get_all = false
        this.type_check = '1'
      }
    },
    check_radio_receive(){
      this.check_etax_get_all = true
      this.check_etax_select = false
      this.fn_get_nametax_block()
    },
    check_radio_except(){
      this.check_etax_get_all = false
      this.check_etax_select = true
      this.fn_get_nametax_accept()
    },
    check_radio_receive_etax_all(){
      this.check_etax_get_all = false
      this.check_etax_select = false
    },
  //   myFunction() {
  //   var dots = document.getElementById("dots");
  //   var moreText = document.getElementById("more");
  //   var btnText = document.getElementById("myBtn");

  //   if (dots.style.display === "none") {
  //     dots.style.display = "inline";
  //     btnText.innerHTML = "Read more"; 
  //     moreText.style.display = "none";
  //   } else {
  //     dots.style.display = "none";
  //     btnText.innerHTML = "Read less"; 
  //     moreText.style.display = "inline";
  //   }
  // }
    // check(){
    //   if(this.checkbox1 == false){
    //     this.checkbox1 = false
    //     this.checkbox2 = true
    //     this.type_check = '1'
    //   }else{
    //     this.checkbox1 = true
    //     this.checkbox2 = false
    //     this.type_check = '0'
    //   }
    // },
    // check2(){
    //   if (this.checkbox2 == false){
    //     this.checkbox2 = false
    //     this.checkbox1 = true
    //     this.type_check = '0'
    //   }else{
    //     this.checkbox2 = true
    //     this.checkbox1 = false
    //     this.type_check = '1'
    //   }
    // }
    // addhashtag() {
    //   if (this.listtaxid === "") {
    //     Toast.fire({
    //       icon: "warning",
    //       title: this.$t("edithashtag.hashtagempty"),
    //     });
    //   } else {
    //     this.dataIndex = this.listtaxid.findIndex((obj) => obj.taxid === this.add_taxid);
    //     console.log("dataIndex",this.dataIndex);
    //     this.data_list_tax.push(this.listtaxid[this.dataIndex]);
    //   }
    // },
    // block_tax(){
    //   if (this.listtaxid === "") {
    //     Toast.fire({
    //       icon: "warning",
    //       title: this.$t("edithashtag.hashtagempty"),
    //     });
    //   } else {
    //     this.dataIndex = this.listtaxid.findIndex((obj) => obj.taxid === this.block_taxid);
    //     console.log("dataIndex",this.dataIndex);
    //     this.data_list_tax.push(this.listtaxid[this.dataIndex]);
    //   }
    // },
    // add(index) {
    //   this.inputs.push({
    //     taxid: "",
    //   });
    // },
    // remove(index) {
    //   this.inputs.splice(index, 1);
    // },
    // add_select(index) {
    //   this.inputs_select.push({
    //     taxid: "",
    //   });
    // },
    // remove_select(index) {
    //   this.inputs_select.splice(index, 1);
    // }
  },
}
</script>
<style>
.v-label{
  font-size: 14px;
}
</style>
